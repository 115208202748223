import {useState} from 'react';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Modal,
} from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ArticleIcon from "@mui/icons-material/Article";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import "./ButtonEditClient.css";
import "./PanelSyncUp.css";
import DisabledByDefaultSharpIcon from '@mui/icons-material/DisabledByDefaultSharp';

const styleViewBillings = {
  position: "absolute",
  zIndex: 8,
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  marginTop: 10,
  p: 2,
};
const ButtonShowBilling = ({id}) => {

  const [open, setOpen] = useState(false);
  const handleOpen = () =>setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button
        style={{ width: "100px" }}
        className="buttonEdit"
        type="button"
        onClick={() => handleOpen()}
      >
        <span className="buttonEdit__text">Ver</span>
        <span className="buttonEdit__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            viewBox="0 0 48 48"
            height="48"
            className="svgEdit"
          >
            <ContentPasteSearchIcon sx={{ color: "white" }} />
          </svg>
        </span>
      </button>
      <Modal open={open}>
        <Box>
          <Paper elevation={4} sx={styleViewBillings}>
            <Box display={"flex"} justifyContent={"flex-end"} >
              <DisabledByDefaultSharpIcon onClick={() =>handleClose()} fontSize="medium"/>
            </Box>
            <Grid container>
              <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "Oswald",
                    fontWeight: 400,
                    fontSize: "28px",
                  }}
                >
                  ¿Cómo desea ver la factura?
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={{ textAlign: "center", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                  marginTop: "10px",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    color: "chocolate",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Oswald",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", width: "120px" }}
                      key={id}
                      href={
                        process.env.REACT_APP_API +
                        "/api/v1/pdf/billing/view?cuf="+
                        id +"&modality="+localStorage.getItem('modality')+"&emissionCode=ONLINE&typePdf=ROLLO"
                      }
                      target="_blank"
                      className="buttonEdit"
                    >
                      <span
                        className="buttonEdit__text"
                        style={{ fontSize: "16px", fontWeight: 400 }}
                      >
                        Rollo
                      </span>
                      <span className="buttonEdit__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          viewBox="0 0 48 48"
                          height="48"
                          className="svgEdit"
                        >
                          <ReceiptLongIcon sx={{ color: "white" }} />
                        </svg>
                      </span>
                    </a>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    color: "goldenrod",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Oswald",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", width: "120px" }}
                      key={id + 1}
                      href={
                        process.env.REACT_APP_API +
                        "/api/v1/pdf/billing/view?cuf=" +
                        id+"&modality="+localStorage.getItem('modality')+"&emissionCode=ONLINE&typePdf=MEDIA"
                      }
                      target="_blank"
                      className="button"
                    >
                      <span
                        className="button__text"
                        style={{ fontSize: "16px", fontWeight: 400 }}
                      >
                        1/2 Página
                      </span>
                      <span className="button__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          viewBox="0 0 48 48"
                          height="48"
                          className="svg"
                        >
                          <ArticleIcon sx={{ color: "white" }} />
                        </svg>
                      </span>
                    </a>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Paper>
        </Box>
      </Modal>
    </div>
  );
}
export default ButtonShowBilling